$primaryColor: #42A5F5;
$fontFamily: 'Roboto', sans-serif;

@import 'primeng/resources/themes/saga-blue/theme';
@import 'primeng/resources/primeng';

body {
  font-family: $fontFamily;
}

.p-button {
  background-color: $primaryColor;
  border-color: $primaryColor;

  &:hover {
    background-color: lighten($primaryColor, 10%);
    border-color: lighten($primaryColor, 10%);
  }
}